import React, { useState, useEffect } from 'react';
import { Dialog, Popover } from '@headlessui/react';
import { Link, useNavigate } from 'react-router-dom';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { FaArrowRight } from 'react-icons/fa';

// Images & Firebase Config
import brandicon from '../assets/preplogo.png';
import { auth, googleProvider } from '../config/FirebaseConfig';
import UserService from '../services/UserService';

// Icons
import { Bars3Icon } from '@heroicons/react/24/outline';

const NavBar = () => {
    const [user, setUser] = useState(null); // Store user data
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const navigate = useNavigate();

    // Check login status on component mount
    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        if (storedUser) {
            setUser(storedUser); // Restore user from localStorage
        }
    }, []);

    // Handle Google Sign-In
    const signInWithGoogle = async () => {
        try {
            const result = await signInWithPopup(auth, googleProvider);
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.idToken;

            const loggedInUser = {
                userId: result.user.uid,
                displayName: result.user.displayName,
                email: result.user.email,
                photoURL: result.user.photoURL,
            };

            await UserService.signInWithGoogle(token, loggedInUser);
            setUser(loggedInUser);
            localStorage.setItem('user', JSON.stringify(loggedInUser));

            // Redirect to profile page after successful login
            navigate('/profile');
        } catch (error) {
            console.error('Google Sign-In Error:', error);
        }
    };

    // Handle Logout with Refresh
    const logout = () => {
        setUser(null);
        localStorage.removeItem('user');
        UserService.signOut();
        navigate('/'); // Redirect to Home
        // window.location.reload(); // Refresh the page after logout
    };

    return (
        <div className='bg-white'>
            <header className='absolute inset-x-0 top-0 z-50'>
                <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
                    <div className="flex lg:flex-1">
                        <Link to="/" className="-m-1.5 p-1.5" onClick={() => setMobileMenuOpen(false)}>
                            <img className="h-12 w-auto" src={brandicon} alt="Prep4USA" />
                        </Link>
                    </div>
                    <div className="flex lg:hidden">
                        <button
                            type="button"
                            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                            onClick={() => setMobileMenuOpen(true)}
                        >
                            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <Popover.Group className="hidden lg:flex lg:gap-x-12">
                        <Link to="/" className="text-sm font-semibold text-gray-700 hover:text-gray-500">
                            Home
                        </Link>
                        {user && (
                            <Link to="/profile" className="text-sm font-semibold text-gray-700 hover:text-gray-500">
                                Profile
                            </Link>
                        )}
                        <Link to="/about" className="text-sm font-semibold text-gray-700 hover:text-gray-500">
                            About Us
                        </Link>
                    </Popover.Group>
                    <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                        {user ? (
                            <button className="btn" onClick={logout}>
                                Logout
                            </button>
                        ) : (
                            <button className="btn" onClick={signInWithGoogle}>
                                Login <FaArrowRight size={15} />
                            </button>
                        )}
                    </div>
                </nav>

                <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={() => setMobileMenuOpen(false)}>
                    <Dialog.Panel className="fixed inset-y-0 right-0 w-full bg-white p-6 sm:max-w-sm">
                        <div className="flex items-center justify-between">
                            <Link to="/" className="-m-1.5 p-1.5" onClick={() => setMobileMenuOpen(false)}>
                                <img className="h-12 w-auto" src={brandicon} alt="Prep4USA" />
                            </Link>
                            <button
                                type="button"
                                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5"
                                onClick={() => setMobileMenuOpen(false)}
                            >
                                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>
                        <div className="mt-6">
                            <div className="space-y-2">
                                <Link to="/" className="block text-base font-semibold" onClick={() => setMobileMenuOpen(false)}>
                                    Home
                                </Link>
                                {user && (
                                    <Link to="/profile" className="block text-base font-semibold" onClick={() => setMobileMenuOpen(false)}>
                                        Profile
                                    </Link>
                                )}
                                <Link to="/about" className="block text-base font-semibold" onClick={() => setMobileMenuOpen(false)}>
                                    About Us
                                </Link>
                            </div>
                            <div className="mt-6">
                                {user ? (
                                    <button className="btn w-full" onClick={logout}>
                                        Logout
                                    </button>
                                ) : (
                                    <button className="btn w-full" onClick={signInWithGoogle}>
                                        Login <FaArrowRight size={15} />
                                    </button>
                                )}
                            </div>
                        </div>
                    </Dialog.Panel>
                </Dialog>
            </header>
        </div>
    );
};

export default NavBar;
