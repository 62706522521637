import React, { useEffect, useState } from 'react';
import { FaUser, FaUniversity, FaCheckCircle, FaEnvelope } from 'react-icons/fa';
import Modal from '../components/Modal';
import UserService from '../services/UserService';
import { useNavigate } from 'react-router-dom';

const Profile = () => {
  const [profile, setProfile] = useState(null);
  const [universities, setUniversities] = useState([]);
  const [university, setUniversity] = useState({ collegeId: '', collegeName: '', userCollegeEmailId: '', collegeAddress: "" });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [emailToVerify, setEmailToVerify] = useState('');
  const [otp, setOtp] = useState('');
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [selectedBatch, setSelectedBatch] = useState('');
  const [batches, setBatches] = useState([]);
  const [otpError, setOtpError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfileData = async () => {
      const loggedIn = await UserService.isUserLoggedIn();
      if (loggedIn) {
        try {
          const profileData = await UserService.getProfileData();
          setProfile(profileData);
          fetchUniversitiesAndBatches();
        } catch (error) {
          console.error('Error fetching profile data:', error);
        }
      } else {
        navigate('/'); // Redirect to home if not logged in
      }
    };

    const fetchUniversitiesAndBatches = async () => {
      try {
        const universityData = await UserService.getUserUniversities();
        setUniversities(Object.values(universityData) || []);
      } catch (error) {
        console.error('Error fetching university data:', error);
      }

      try {
        const batchData = await UserService.getBatches();
        setBatches(batchData || []);
      } catch (error) {
        console.error('Error fetching batch data:', error);
      }
    };

    fetchProfileData();
  }, [navigate]);

  const handleUniversityChange = (e) => {
    const { name, value } = e.target;
    setUniversity({ ...university, [name]: value });
  };

  const handleBatchChange = (e) => {
    setSelectedBatch(e.target.value);
  };

  const verifyOTP = () => {
    return new Promise((resolve) => {
      if (otp) {
        const id = batches.find(batch => batch.batchName === selectedBatch)?.id;

        UserService.verifyOTP(university.userCollegeEmailId, id, otp)
          .then(() => {
            setOtpError(''); // Clear error on success
            resolve(true); // Verification successful
          })
          .catch((error) => {
            console.error('Error verifying OTP:', error);
            setOtpError('Wrong OTP'); // Set error message
            resolve(false); // Verification failed
          });
      } else {
        setOtpError('Invalid OTP'); // Set error if OTP is empty
        resolve(false); // Invalid OTP
      }
    });
  };

  const addUniversity = () => {
    if (university.userCollegeEmailId) {
      setEmailToVerify(university.userCollegeEmailId);
      setIsModalOpen(true);
      setOtpError(''); // Reset error when opening modal
    }
  };

  const handleVerifyAndAdd = () => {
    verifyOTP().then((result) => {
      if (result) {
        UserService.getUserUniversities()
          .then((universityData) => {
            setUniversities(Object.values(universityData) || []);
          })
          .catch((error) => {
            console.error('Error fetching university data:', error);
          });
        closeModal();
      }
    }).catch((error) => {
      setOtpError(error);
      console.error('Error verifying OTP:', error);
    });
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEmailToVerify('');
    setOtp('');
    setOtpError(''); // Clear error message on close
  };

  const getOTP = () => {
    UserService.getOTP(emailToVerify)
      .then(() => {
        setIsOTPSent(true);
        setOtpError(''); // Clear any previous errors
      })
      .catch((error) => {
        console.error('Error sending OTP:', error);
      });
  };

  if (!profile) {
    return (
      <div className="container mx-auto flex flex-col items-center justify-center min-h-screen p-4">
        <h2 className="text-3xl font-bold text-blue-800 mb-6">Please Log In</h2>
        <p className="text-lg text-gray-700 text-center mb-6 max-w-lg">
          We're excited to help you on your journey to the US! Please log in to view your profile and access all our features.
          After logging in and verifying your university email, you’ll be able to chat with peers/alumni and track your progress.
        </p>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 mt-24">
      <div className="isolate mt-44 mb-36 p-6 mx-auto max-w-sm sm:max-w-xl md:max-w-full lg:max-w-screen-xl">
        {isModalOpen && (
          <Modal isOpen={isModalOpen} onClose={closeModal} title="Verify Email">
            {!isOTPSent ? (
              <div>
                <p>Send OTP to {emailToVerify}?</p>
                <label className="block mt-4">
                  <span className="text-blue-700 font-semibold">Select Batch:</span>
                  <select
                    value={selectedBatch}
                    onChange={handleBatchChange}
                    className="mt-2 p-2 border border-gray-300 rounded-md w-full"
                  >
                    <option value="" disabled>Select Batch</option>
                    {batches.map((batch) => (
                      <option key={batch.id} value={batch.batchName}>
                        {batch.batchName}
                      </option>
                    ))}
                  </select>
                </label>
                <div className="mt-4 flex justify-end gap-4">
                  <button
                    onClick={closeModal}
                    className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={getOTP}
                    className="px-4 py-2 bg-blue-700 text-white rounded-md hover:bg-blue-800"
                    disabled={!selectedBatch}
                  >
                    Send OTP
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <p>Please enter the OTP sent to {emailToVerify} (Batch: {selectedBatch}):</p>
                {otpError && <p className="text-red-600">{otpError}</p>}
                <input
                  type="text"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  className="mt-2 px-4 py-2 border rounded-md w-full"
                  placeholder="Enter OTP"
                />
                <div className="mt-4 flex justify-end gap-4">
                  <button
                    onClick={closeModal}
                    className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleVerifyAndAdd}
                    className="px-4 py-2 bg-blue-700 text-white rounded-md hover:bg-blue-800"
                  >
                    Verify OTP
                  </button>
                </div>
              </div>
            )}
          </Modal>
        )}

        <div className="max-w-xl text-center mx-auto lg:max-w-2xl mb-12">
          <h2 className="mb-6">
            <span className="relative inline-block">
              <span className="relative text-primary title">Your Profile</span>
            </span>
          </h2>
          <div className="flex justify-center mt-5">
            {profile.profilePhoto ? (
              <img src={profile.profilePhoto} alt="Profile" className="rounded-full border-2 border-blue-700 w-24 h-24" />
            ) : (
              <div className="w-24 h-24 rounded-full bg-blue-50 flex items-center justify-center">
                <FaUser className="text-blue-700 w-12 h-12" />
              </div>
            )}
          </div>
          <div className="mt-8">
            <label className="block mb-4">
              <span className="text-blue-700 font-semibold flex items-center">
                <FaUser className="mr-2" /> Name:
              </span>
              <input
                type="text"
                name="name"
                value={profile.displayName}
                readOnly
                className="mt-2 p-2 border border-gray-300 rounded-md w-full"
              />
            </label>
            <label className="block mb-4">
              <span className="text-blue-700 font-semibold flex items-center">
                <FaEnvelope className="mr-2" /> Email:
              </span>
              <input
                type="email"
                name="email"
                value={profile.email}
                readOnly
                className="mt-2 p-2 border border-gray-300 rounded-md w-full"
              />
            </label>
            <div className="mt-8">
              <h2 className="text-2xl font-bold text-blue-800 mb-4">University Admits</h2>
              <div className="flex items-center gap-2">
                <input
                  type="email"
                  name="userCollegeEmailId"
                  value={university.userCollegeEmailId}
                  onChange={handleUniversityChange}
                  placeholder="University Email"
                  className="p-2 border border-gray-300 rounded-md flex-1"
                />
                <button
                  onClick={addUniversity}
                  className="px-4 py-2 bg-blue-700 text-white rounded-md hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-150 ease-in-out"
                >
                  Add University
                </button>
              </div>
              <ul className="mt-4">
                {universities.length > 0 && universities.map((uni, index) => (
                  <li key={index} className="flex items-center justify-between p-2 border-b">
                    <div className="flex items-center">
                      <FaUniversity className="mr-2" />
                      <span>{uni.collegeName}</span>
                    </div>
                    <div className="flex items-center">
                      <FaCheckCircle className="text-green-600 mr-2" />
                      <span>{uni.userCollegeEmailId}</span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
