import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider} from "firebase/auth";

//Firebase Config
export const firebaseConfig = {
    apiKey: "AIzaSyCmhnTPRIWtT_sVzrOj3yM0YdXoTTlA3Tg",
    authDomain: "prep4usa-8f310.firebaseapp.com",
    projectId: "prep4usa-8f310",
    storageBucket: "prep4usa-8f310.appspot.com",
    messagingSenderId: "71430975203",
    appId: "1:71430975203:web:273f064c32dff24afc5fd6",
    measurementId: "G-0DXF1BPLWS"
  };

  // Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const authProvider = auth;
export const googleProvider = new GoogleAuthProvider();