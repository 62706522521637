import React, { useEffect, useState } from 'react';
import { FaCheckCircle, FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import TaskService from '../services/TaskService'; // Assuming you have a TaskService for API calls

const TaskDetails = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { taskId, taskName, videoUrl } = location.state || {};
    const [steps, setSteps] = useState([]);
    const [subSteps, setSubSteps] = useState({});
    const [selectedVideoUrl, setSelectedVideoUrl] = useState(videoUrl);
    const [selectedStep, setSelectedStep] = useState(null);
    const [selectedSubStep, setSelectedSubStep] = useState(null);
    const [completedSteps, setCompletedSteps] = useState([]);
    const [completedSubSteps, setCompletedSubSteps] = useState({});
    const [expandedSteps, setExpandedSteps] = useState({});
    const [activeTab, setActiveTab] = useState('overview');

    useEffect(() => {
        const taskService = new TaskService();

        const fetchTasks = async () => {
            try {
                const { steps, subSteps } = await taskService.getAllTasksByParent(taskId);
                setSteps(Object.values(steps).flat() || []);
                setSubSteps(subSteps || {});

                // Set initial selected step and video URL
                if (steps && Object.values(steps).flat().length > 0) {
                    const initialStep = Object.values(steps).flat()[0];
                    setSelectedStep(initialStep);
                    setSelectedVideoUrl(initialStep.assetUrl || videoUrl);
                }
            } catch (error) {
                console.error('Error fetching tasks:', error);
            }
        };

        fetchTasks();
    }, [taskId, videoUrl]);

    const handleStepCompletionToggle = (stepId) => {
        setCompletedSteps(prevState =>
            prevState.includes(stepId)
                ? prevState.filter(id => id !== stepId)
                : [...prevState, stepId]
        );
    };

    const handleSubStepCompletionToggle = (subStepId) => {
        setCompletedSubSteps(prevState => ({
            ...prevState,
            [subStepId]: !prevState[subStepId],
        }));
    };

    const handleSubStepSelection = (subStep) => {
        setSelectedSubStep(subStep);
        setActiveTab('overview');
        setSelectedVideoUrl(subStep.assetUrl || videoUrl);
    };

    const toggleExpandStep = (stepId) => {
        setExpandedSteps(prevState => ({
            ...prevState,
            [stepId]: !prevState[stepId],
        }));
        const step = steps.find(step => step.taskId === stepId);
        if (step) {
            setSelectedStep(step);
            setSelectedSubStep(null);
            setSelectedVideoUrl(step.assetUrl || videoUrl);
        }
    };

    const handlePrevNext = (direction) => {
        if (!selectedStep) return;
        const currentIndex = steps.findIndex(step => step.taskId === selectedStep.taskId);
        const newIndex = currentIndex + direction;
        if (newIndex >= 0 && newIndex < steps.length) {
            const newStep = steps[newIndex];
            setSelectedStep(newStep);
            setSelectedSubStep(null);
            setActiveTab('overview');
            setSelectedVideoUrl(newStep.assetUrl || videoUrl);
        }
    };

    const handlePrevNextSubStep = (direction) => {
        if (!selectedStep || !subSteps[selectedStep.taskId]) return;
        const currentSubSteps = subSteps[selectedStep.taskId];
        const currentIndex = currentSubSteps.findIndex(subStep => subStep.taskId === selectedSubStep?.taskId);
        const newIndex = currentIndex + direction;
        if (newIndex >= 0 && newIndex < currentSubSteps.length) {
            setSelectedSubStep(currentSubSteps[newIndex]);
            setActiveTab('overview');
        }
    };

    if (!steps.length) {
        return <div>Loading...</div>;
    }

    return (
        <div className="container mx-auto p-6 mt-24">
            <h1 className="text-3xl font-semibold mb-6 text-center">{taskName}</h1>

            <div className="w-full bg-gray-200 rounded-full h-6 mb-6">
                <div
                    className="bg-blue-500 h-6 rounded-full"
                    style={{ width: `${(completedSteps.length / steps.length) * 100}%` }}
                >
                    <span className="text-white font-bold ml-2">
                        {Math.round((completedSteps.length / steps.length) * 100)}% Complete
                    </span>
                </div>
            </div>

            <button
                onClick={() => navigate('/')}
                className="mb-6 px-4 py-2 bg-blue-500 text-white rounded-lg shadow hover:bg-blue-600 transition duration-200"
            >
                Back to Checklist
            </button>

            <div className="flex flex-wrap md:flex-nowrap">
                <div className="w-full md:w-1/3 bg-blue-50 p-4 rounded-lg shadow-lg mb-6">
                    <h2 className="text-lg font-semibold text-blue-700 mb-4">Contents</h2>
                    <ul>
                        {steps.map(step => (
                            <li key={step.taskId} className="mb-2">
                                <div className="flex items-center justify-between">
                                    <button
                                        onClick={() => toggleExpandStep(step.taskId)}
                                        className={`text-left w-full p-2 rounded-md hover:bg-blue-100 transition ${
                                            selectedStep?.taskId === step.taskId ? 'bg-blue-200' : 'bg-white'
                                        }`}
                                    >
                                        {step.taskName}
                                    </button>
                                    {subSteps[step.taskId] && subSteps[step.taskId].length > 0 && (
                                        expandedSteps[step.taskId] ? (
                                            <FaCaretUp className="ml-2" />
                                        ) : (
                                            <FaCaretDown className="ml-2" />
                                        )
                                    )}
                                    {completedSteps.includes(step.taskId) && (
                                        <FaCheckCircle className="text-green-500 ml-2" />
                                    )}
                                </div>
                                {expandedSteps[step.taskId] && subSteps[step.taskId] && (
                                    <div className="ml-4 mt-2">
                                        {subSteps[step.taskId].map(subStep => (
                                            <div
                                                key={subStep.taskId}
                                                className={`p-3 rounded-lg border ${
                                                    selectedSubStep?.taskId === subStep.taskId
                                                        ? 'border-blue-500 bg-blue-50'
                                                        : 'border-gray-300 bg-white'
                                                } hover:bg-blue-50 transition cursor-pointer flex items-center justify-between`}
                                                onClick={() => handleSubStepSelection(subStep)}
                                            >
                                                <span>{subStep.taskName}</span>
                                                {completedSubSteps[subStep.taskId] && (
                                                    <FaCheckCircle className="text-green-500" />
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="w-full md:w-2/3 ml-0 md:ml-6">
                    <div className="bg-white p-6 rounded-lg shadow-lg mb-6">
                        <div className="video-player mb-4">
                            <iframe
                                width="100%"
                                height="315"
                                src={selectedVideoUrl}
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin"
                                allowFullScreen
                            ></iframe>
                        </div>

                        <div className="tabs">
                            <div className="flex border-b">
                                <button
                                    onClick={() => setActiveTab('overview')}
                                    className={`w-1/2 py-2 px-4 ${activeTab === 'overview' ? 'bg-blue-100' : 'bg-white'} border-b-2 border-blue-500`}
                                >
                                    Overview
                                </button>
                                <button
                                    onClick={() => setActiveTab('faq')}
                                    className={`w-1/2 py-2 px-4 ${activeTab === 'faq' ? 'bg-blue-100' : 'bg-white'} border-b-2 border-blue-500`}
                                >
                                    FAQ
                                </button>
                            </div>
                            <div className="p-4 bg-white flex-grow">
                                {activeTab === 'overview' && (
                                    <div>
                                        <h3 className="text-lg font-semibold mb-2">Overview</h3>
                                        <p dangerouslySetInnerHTML={{ __html: selectedSubStep ? selectedSubStep.blog : selectedStep?.blog }} />
                                    </div>
                                )}
                                {activeTab === 'faq' && (
                                    <div>
                                        <h3 className="text-lg font-semibold mb-2">FAQ</h3>
                                        <p dangerouslySetInnerHTML={{ __html: selectedSubStep ? selectedSubStep.faq : selectedStep?.faq}} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-between items-center">
                        {selectedSubStep ? (
                            <>
                                <button
                                    onClick={() => handlePrevNextSubStep(-1)}
                                    className={`py-2 px-4 rounded-lg ${
                                        subSteps[selectedStep.taskId]?.[0].taskId === selectedSubStep.taskId
                                            ? 'bg-gray-300 cursor-not-allowed'
                                            : 'bg-blue-500 text-white'
                                    }`}
                                    disabled={subSteps[selectedStep.taskId]?.[0].taskId === selectedSubStep.taskId}
                                >
                                    Previous
                                </button>
                                <button
                                    onClick={() => handleSubStepCompletionToggle(selectedSubStep.taskId)}
                                    className={`py-2 px-6 rounded-lg font-semibold ${
                                        completedSubSteps[selectedSubStep.taskId] ? 'bg-red-500 text-white' : 'bg-green-500 text-white'
                                    }`}
                                >
                                    {completedSubSteps[selectedSubStep.taskId] ? 'Undo' : 'Mark Complete'}
                                </button>
                                <button
                                    onClick={() => handlePrevNextSubStep(1)}
                                    className={`py-2 px-4 rounded-lg ${
                                        subSteps[selectedStep.taskId]?.[subSteps[selectedStep.taskId].length - 1].taskId === selectedSubStep.taskId
                                            ? 'bg-gray-300 cursor-not-allowed'
                                            : 'bg-blue-500 text-white'
                                    }`}
                                    disabled={subSteps[selectedStep.taskId]?.[subSteps[selectedStep.taskId].length - 1].taskId === selectedSubStep.taskId}
                                >
                                    Next
                                </button>
                            </>
                        ) : (
                            <>
                                <button
                                    onClick={() => handlePrevNext(-1)}
                                    className={`py-2 px-4 rounded-lg ${steps[0].taskId === selectedStep?.taskId ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500 text-white'}`}
                                    disabled={steps[0].taskId === selectedStep?.taskId}
                                >
                                    Previous
                                </button>
                                <button
                                    onClick={() => handleStepCompletionToggle(selectedStep?.taskId)}
                                    className={`py-2 px-6 rounded-lg font-semibold ${
                                        completedSteps.includes(selectedStep?.taskId) ? 'bg-red-500 text-white' : 'bg-green-500 text-white'
                                    }`}
                                >
                                    {completedSteps.includes(selectedStep?.taskId) ? 'Undo' : 'Mark Complete'}
                                </button>
                                <button
                                    onClick={() => handlePrevNext(1)}
                                    className={`py-2 px-4 rounded-lg ${steps[steps.length - 1].taskId === selectedStep?.taskId ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500 text-white'}`}
                                    disabled={steps[steps.length - 1].taskId === selectedStep?.taskId}
                                >
                                    Next
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TaskDetails;
