import React from 'react'
import ProgramHero from '../components/ProgramHero'
import Testimonial from '../components/Testimonial'
import ContactUs from '../components/ContactUs'

const Programs = () => {
    return (
        <>
            {/* <div className='mb-36 space-y-40'> */}
            <div>
                <ProgramHero />
                <Testimonial />
                <ContactUs/>
            </div>
        </>
    )
}

export default Programs