import axios from 'axios';

class TaskService {
    API_BASE_URL = 'https://stagingapi.prep4usa.com:8444/pfu-rest-api/api/v1';
    // API_BASE_URL = 'http://localhost:8080/api/v1';

    getParentTasks = async () => {
        try {
            const response = await axios.get(`${this.API_BASE_URL}/tasks?parentId=0&collegeId=0&recursive=false`);
            return response.data;
        } catch (error) {
            console.error('Error fetching tasks:', error);
        }
    };

    getAllTasksByParent = async (parent_id) => {
        const response = await axios.get(`${this.API_BASE_URL}/tasks?parentId=${parent_id}&collegeId=0&recursive=true`);
        return this.processTasks(response.data);
    };

    processTasks = async (tasks) => {
        try {
            const steps = {}; // Dictionary to store steps with parent_id as key
            const subSteps = {}; // Dictionary to store subSteps with parent_id as key
            
            for (const task of tasks) {
                if(task.taskType === 1) {
                    
                }
                else if (task.taskType === 2) {
                    if (!steps[task.parentId]) {
                        steps[task.parentId] = [];
                    }
                    steps[task.parentId].push(task);
                } else {
                    if (!subSteps[task.parentId]) {
                        subSteps[task.parentId] = [];
                    }
                    subSteps[task.parentId].push(task);
                }
            }
            
            console.log("Steps:", Object.values(steps));
            console.log("SubSteps:", Object.values(subSteps));
            return { steps, subSteps }; // Return arrays for steps and dictionary for subSteps
    
        } catch (error) {
            console.error('Error processing tasks:', error);
            return {steps: {}, subSteps: {} }; // Return empty objects in case of an error
        }
    };

    // Get All Tasks
    getAllTasks = async () => {
        try {
            const response = await axios.get(`${this.API_BASE_URL}/tasks/all`);
            console.log('Response from API:', response.data);
            this.taskRespose = response.data; // Cache the tasks
            console.log('Tasks fetched successfully');
        } catch (error) {
            console.error('Error fetching tasks:', error);
            this.tasks = []; // Set to an empty array if there's an error
        }
        return this.taskRespose;
    };

}

export default TaskService;
